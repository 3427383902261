<template>
  <WsRead
    :modelName="modelName"
    :id="$route.params.id"
    :localeMode="localeMode"
    :label="label"
    :fields="fields"
    :primary="pageSetting.primary"
    :secondary="_pageSettingSecondary"
    titleKey="name"
  >
  </WsRead>
</template>

<script>
import S_Stone_Wasa from "@/__vue2stone_cms/service/wasa";
import model from "@/__vue2stone_cms/models/service_store";
export default {
  data() {
    return {
      // modelName: model.modelName,
      // label: model.label,
      // fields: model.fields,
      ...model,
      localeMode: false,
      pageSetting: {
        primary: [
          {
            type: "infoCard",
            floors: [
              {
                fields: [
                  "name",
                  "business_hours",
                  "appointment_availables",
                  "tel",
                  "address",
                  "des",
                  "transportation_info",
                  // "parking_info",
                  // "parking_link",
                  "parking_infos",
                  "parking_image",
                  "notify_emails",
                ],
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    _pageSettingSecondary() {
      const fields = [
        "is_active",
        "cover_image",
        "lat",
        "lng",
        "work_on_holiday",
        "service_at_night",
        "today_appointments_notify_time",
        "tomorrow_appointments_notify_time",
      ];
      if (S_Stone_Wasa.hasScope(["boss"])) {
        if (this.$config.auth.admin_group) {
          if (this.$config.auth.admin_blur) {
            fields.push("cmser_groups");
          } else {
            fields.push("admin_groups");
          }
        }
      }
      if (this.$config.wsmodule.area) {
        fields.push("area");
      }
      return [
        {
          type: "infoCard",
          floors: [
            {
              title: this.$t("設定"),
              fields: fields,
            },
          ],
        },
      ];
    },
  },
};
</script>